import { combineReducers } from 'redux'
import AuthUserReducer from './AuthUserReducer'
import ContactReducer from './ContactReducer'
import AppSettingsReducer from './AppSettingsReducer'
import UserSettingsReducer from './UserSettingsReducer'
import MenuListReducer from './MenuListReducer'
import AuthReducer from './auth'
import FormInfoReducer from './FormInfoReducer';
import UserMasterReducer from './master/UserMasterReducer'
import SettingMasterReducer from './master/SettingMasterReducer'
import DashboardReducer from './DashboardReducer'
import DesignationMasterReducer from './master/DesignationMasterReducer';
import DepartmentMasterReducer from './master/DepartmentMasterReducer';
import AreaMasterReducer from './master/AreaMasterReducer';
import WardMasterReducer from './master/WardMasterReducer';
import LegalReducer from './LegalReducer/LegalReducer'
import listOfApplicationReducer from './LegalHeir/listOfApplicationReducer'
import applicationReportReducer from './LegalHeir/ApplicationReportReducer'

const reducers = combineReducers({
	authUser: AuthUserReducer,
	settings: AppSettingsReducer,
	ContactReducer: ContactReducer,
	UserSettingsReducer: UserSettingsReducer,
	menuListReducer: MenuListReducer,
	authReducer: AuthReducer,
	formInfoReducer: FormInfoReducer,
	userMasterReducer: UserMasterReducer,
	settingMasterReducer: SettingMasterReducer,
	dashboardReducer: DashboardReducer,
	designationMasterReducer: DesignationMasterReducer,
	departmentMasterReducer: DepartmentMasterReducer,
	areaMasterReducer: AreaMasterReducer,
	wardMasterReducer: WardMasterReducer,
	legalReducer: LegalReducer,
	listOfApplicationReducer: listOfApplicationReducer,
	applicationReportReducer: applicationReportReducer,

});

export default reducers;